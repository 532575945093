import React from 'react';
import { Typography } from '@mui/material';
import { Translate } from 'react-localize-redux';
import { EnhancedTable } from '../../../components/general/EnhancedTable';
import Loader from '../../../components/Loader';
import { useResearcherStats } from '../../../hooks/analytics';

export interface ResearcherSubmissionStats {
    researcher: {
        id: number;
        name: string;
        surnames: string;
    };
    submissions: {
        [surveyId: number]: {
            surveyName: string;
            count: number;
        };
    };
    totalSubmissions: number;
}
export interface SurveyResearcherStatsPureProps {
    loading: boolean;
    surveys: {
        id: number;
        name: string;
    }[];
    researchers: ResearcherSubmissionStats[];
}

// Pure presentational component
export const SurveyResearcherStatsPure: React.FC<SurveyResearcherStatsPureProps> = ({ 
    loading, 
    surveys, 
    researchers 
}) => {
    if (loading) {
        return <Loader />;
    }

    // Create header cells: researcher name + all survey names
    const headCells = [
        { 
            id: "researcher", 
            alignment: "left", 
            label: <Translate id={`hospital.staff`} /> 
        },
        ...surveys.map(survey => ({
            id: `survey_${survey.id}`,
            alignment: "left" as const,
            label: survey.name.toLowerCase().split(' ').map(word => 
                word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' ')
        }))
    ];

    // Transform the data for the table
    const rows = researchers.map(researcher => {
        const row: any = {
            researcher: researcher.researcher.name.charAt(0).toUpperCase() + researcher.researcher.name.slice(1) + " " + 
                       researcher.researcher.surnames.charAt(0).toUpperCase() + researcher.researcher.surnames.slice(1),
        };
        
        surveys.forEach(survey => {
            const submissionData = researcher.submissions[survey.id];
            row[`survey_${survey.id}`] = submissionData ? submissionData.count : 0;
        });

        return row;
    });

    return (
        <EnhancedTable 
            noHeader 
            noSelectable={true} 
            rows={rows} 
            headCells={headCells} 
        />
    );
};

// Container component with hook
interface SurveyResearcherStatsProps {
    startDate: number;
    endDate: number;
    uuidInvestigation: string;
}

const SurveyResearcherStats: React.FC<SurveyResearcherStatsProps> = ({ startDate, endDate, uuidInvestigation }) => {
    const { loading, error, surveys, researchers } = useResearcherStats(uuidInvestigation, startDate, endDate);
    
    if (error) {
        return <Typography color="error">{error.message}</Typography>;
    }

    return (
        <SurveyResearcherStatsPure 
            key={startDate + endDate}
            loading={loading}
            surveys={surveys}
            researchers={researchers}
        />
    );
};

export default SurveyResearcherStats;
